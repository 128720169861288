    <template>
<section class="section fully-center">
  <h1 class="title">Following the map</h1>
  <h2 class="subtitle">
    and then some more.
  </h2>
</section>
<div class="columns is-desktop is-vcentered has-another-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/launch.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Launch!</h1>
  <h2 class="subtitle has-text-warning">
    Polish yer cannonballs, and hold on to somn'.
      <ul>
    <li>Storyline Begins.</li>
    <li>Web launch.</li>
    <li>Discord Gamification.</li>
  </ul>
  </h2>
  </div>
  </div>

<div class="columns is-desktop is-vcentered has-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/10.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Waxing our vessel, ironig them sails!</h1>
  <h2 class="subtitle has-text-warning">
      <ul>
    <li>5 Evil Spectral POTOS (special edition) will be raffled among our cap'n's</li>
    <li>5 Ledger Hardware Wallet giveaway fer holders t' keep their booty safe</li>
    <li>POTOS giveaways </li>
    <li>Merch Shop will open</li>
    <li>Sponsor a water project t' provide clean, safe and reliable water t' a large community that needs it</li>

  </ul>
  </h2>
  </div>
  </div>


<div class="columns is-desktop is-vcentered has-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/25.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Fire in the Hole! Celebration.
</h1>
  <h2 class="subtitle has-text-warning">
      <ul>
    <li>20 Ledgers giveaway among captains.</li>
    <li>Pirate giveaways.</li>
    <li>5 Evil Spectral POTOS (special edition) raffle among our captains</li>
    <li>Animal companions join POTOS in their adventure! (Free minting + gas) t' holders</li>
    <li>POTOS giveaway</li>
    <li> Sponsor a water project t' provide clean, safe and reliable water t' a large community that needs it</li>

  </ul>
  </h2>
  </div>
  </div>


<div class="columns is-desktop is-vcentered has-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/50.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Ship maintenance and upgrade. Many goodies!</h1>
  <h2 class="subtitle has-text-warning">
      <ul>
    <li>25 Ledgers giveaway among captains.</li>
    <li>POTOS giveaways.</li>
    <li>12 Evil Spectral POTOS (special edition)will be raffled among our captains</li>
    <li>Sponsor water project t' provide clean, safe and reliable water.</li>

  </ul>
  </h2>
  </div>
  </div>


<div class="columns is-desktop is-vcentered has-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/75.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Tierra a la Vista! We approach ETH Island</h1>
  <h2 class="subtitle has-text-warning">
      <ul>
    <li>25 Ledgers giveaway among captains.</li>
    <li>POTOS giveaways.</li>
    <li>12 Evil Spectral POTOS (special edition) raffled among our cap'n's</li>
    <li>Sponsor water project t' provide clean, safe and reliable water.</li>

  </ul>
  </h2>
  </div>
  </div>

<div class="columns is-desktop is-vcentered has-background-black">
  <div class="column is-one-third fully-center">  <img src="../../assets/100.png"></div>
  <div class="column">
  <h1 class="title has-text-white">Get our crew into dry land. Resupply and get ready for our next adveture</h1>
   <h2 class="subtitle has-text-warning">

      <ul>
    <li>25 Ledgers giveaway among captains</li>
    <li>POTOS giveaways</li>
    <li>12 Evil Spectral POTOS (special edition) raffle among our captains</li>
    <li>Sponsor water project t' provide clean, safe and reliable water.</li>
  </ul>
</h2>
  </div>
  </div>
  

</template>

<style scoped>
</style>